@import 'colors.css';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&family=IBM+Plex+Sans+Condensed:wght@500;600&display=swap');

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: var(--primary-font-family);
}

* {
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

:root {
  /* font-families */
  --primary-font-family: 'DM Sans', 'Segoe UI', Tahoma, sans-serif;
  --secondary-font-family: 'IBM Plex Sans Condensed', 'Segoe UI', Tahoma,
    sans-serif;

  --ui-weight-regular: 400;
  --ui-weight-medium: 500;
  --ui-weight-semi-bold: 600;
  --ui-weight-bold: 700;

  --ui-size-xtra-small: 10px;
  --ui-size-small: 12px;
  --ui-size-medium: 14px;
  --ui-size-large: 16px;
  --ui-size-xtra-large: 18px;

  --headline-size-xtra-small: 18px;
  --headline-size-small: 20px;
  --headline-size-medium: 24px;
  --headline-size-large: 28px;
  --headline-size-xtra-large: 32px;

  /* --ui-size-weight --font-weight --font-size-- / --line-height-- --font-family-- */
  --ui-small-regular: var(--ui-weight-regular) var(--ui-size-small) / 130%
    var(--primary-font-family);
  --ui-small-regular-caps: var(--ui-weight-regular) var(--ui-size-small) / 130%
    var(--primary-font-family);
  --ui-small-medium: var(--ui-weight-medium) var(--ui-size-small) / 130%
    var(--primary-font-family);
  --ui-small-bold: var(--ui-weight-bold) var(--ui-size-small) / 130%
    var(--primary-font-family);

  --ui-medium-regular: var(--ui-weight-regular) var(--ui-size-medium) / 143%
    var(--primary-font-family);
  --ui-medium-medium: var(--ui-weight-medium) var(--ui-size-medium) / 143%
    var(--primary-font-family);
  --ui-medium-bold: var(--ui-weight-bold) var(--ui-size-medium) / 143%
    var(--primary-font-family);

  --ui-large-regular: var(--ui-weight-regular) var(--ui-size-large) / 150%
    var(--primary-font-family);
  --ui-large-medium: var(--ui-weight-medium) var(--ui-size-large) / 150%
    var(--primary-font-family);
  --ui-large-bold: var(--ui-weight-bold) var(--ui-size-large) / 150%
    var(--primary-font-family);

  --ui-xtra-large-bold: var(--ui-weight-bold) var(--ui-size-xtra-large) / 150%
    var(--primary-font-family);

  --headline-xtra-small: var(--ui-weight-semi-bold)
    var(--headline-size-xtra-small) / 120% var(--secondary-font-family);
  --headline-small: var(--ui-weight-semi-bold) var(--headline-size-small) / 120%
    var(--secondary-font-family);
  --headline-medium: var(--ui-weight-semi-bold) var(--headline-size-medium) /
    120% var(--secondary-font-family);
  --headline-large: var(--ui-weight-semi-bold) var(--headline-size-large) /
    123.5% var(--secondary-font-family);
  --headline-xtra-large: var(--ui-weight-semi-bold)
    var(--headline-size-xtra-large) / 123.5% var(--secondary-font-family);

  /* z-index */
  --header-z: 10;
  --modal-z: 1000;
  --select-menu-z: 1000;
  --tooltip-z: 1200;

  /* modal*/
  --modal-header-background: var(--primary);
  --modal-header-color: var(--white);
  --modal-header-color-hover: var(--primary-200);
  --modal-header-color-active: var(--primary-200);
  --modal-footer-border-top: var(--blue-grey-100);

  /* button */
  --button-primary-background-color: var(--secondary);
  --button-primary-background-color-hover: var(--secondary-700);
  --button-primary-background-color-active: var(--secondary-800);
  --button-outline-border-color: var(--secondary);
  --button-outline-border-color-hover: var(--secondary-700);
  --button-outline-border-color-active: var(--secondary-800);
  --button-outline-icon-color: var(--blue-grey-900);
  --button-outline-color-hover: var(--blue-grey-900);
  --button-text-color: var(--blue-grey-900);
  --button-text-color-hover: var(--secondary-700);
  --button-text-color-active: var(--secondary-800);
}
