:root {
  /* design system colors */
  --blue-grey-25: #f9fbfc;
  --blue-grey-35: #f3f5f6;
  --blue-grey-50: #eceff1;
  --blue-grey-100: #cfd8dc;
  --blue-grey-150: #cdd6da;
  --blue-grey-200: #b0bec5;
  --blue-grey-250: #a1b2ba;
  --blue-grey-300: #90a4ae;
  --blue-grey-350: #809782;
  --blue-grey-400: #78909c;
  --blue-grey-500: #607d8b;
  --blue-grey-600: #546e7a;
  --blue-grey-700: #455a64;
  --blue-grey-900: #263238;

  /* --primary derived from #263238 / --blue-grey-900 */
  --primary: hsl(200, 19.1%, 18.4%);
  --primary-25: hsl(200, 19.1%, 98%);
  --primary-35: hsl(200, 19.1%, 96%);
  --primary-50: hsl(200, 19.1%, 94%);
  --primary-100: hsl(200, 19.1%, 86%);
  --primary-150: hsl(200, 19.1%, 83%);
  --primary-200: hsl(200, 19.1%, 73%);
  --primary-300: hsl(200, 19.1%, 62%);
  --primary-350: hsl(200, 19.1%, 58%);
  --primary-400: hsl(200, 19.1%, 54%);
  --primary-500: hsl(200, 19.1%, 46%);
  --primary-600: hsl(200, 19.1%, 40%);
  --primary-700: hsl(200, 19.1%, 33%);
  --primary-800: hsl(200, 19.1%, 26%);
  --primary-900: hsl(200, 19.1%, 18%);

  /* --secondary derived from #009500 / --green-500 / LA Green */
  --secondary: hsl(120, 90%, 29.2%);
  --secondary-25: hsl(120, 90%, 94%);
  --secondary-35: hsl(120, 90%, 90%);
  --secondary-50: hsl(120, 90%, 84%);
  --secondary-100: hsl(120, 90%, 76%);
  --secondary-150: hsl(120, 90%, 73%);
  --secondary-200: hsl(120, 90%, 63%);
  --secondary-300: hsl(120, 90%, 52%);
  --secondary-350: hsl(120, 90%, 48%);
  --secondary-400: hsl(120, 90%, 44%);
  --secondary-500: hsl(120, 90%, 38%);
  --secondary-600: hsl(120, 90%, 30%);
  --secondary-700: hsl(120, 90%, 23%);
  --secondary-800: hsl(120, 90%, 16%);
  --secondary-900: hsl(120, 90%, 8%);

  --blue-300: #72a1fc;

  --green-25: #f8fcf8;
  --green-200: #80ca80;
  --green-500: #009500;
  --green-600: #007a00;
  --green-700: #006800;

  --red-25: #fee6e6;
  --red-500: #d90606;
  --red-600: #c70505;

  --black: #000000;
  --error: #d90606;
  --red: #d90606;
  --white: #ffffff;

  --drop-shadow: rgba(132, 157, 175, 0.25);
  --transparent-overlay: rgba(0, 0, 0, 0);
  /* Blue grey 900 at 35% opacity */
  --translucent-overlay: rgba(38, 50, 56, 0.35);
}
